import React from "react"
import "../css/404.scss"
import Layout from "../components/layout/layout"
import { GlobalHead } from "../helpers/helpers"

function PageContent() {
    return (
        <div className="error">
            <p className="error__code">404</p>
            <p className="error__message">
                Oops, sorry we can&#39;t find that page
            </p>
        </div>
    )
}

export function Head() {
    return <GlobalHead></GlobalHead>
}

export default function Error404() {
    return <Layout>{PageContent()}</Layout>
}
