import React, { useEffect } from "react"
import PropTypes from "prop-types"
import "../../css/base.scss"
import "normalize.css"
import Header from "../header/header.js"
import Footer from "../footer/footer.js"
import Content from "../content/content.js"

function Layout({ children }) {
    useEffect(() => {
        document
            .querySelectorAll(
                ".photo-grid__item, .photo-list__item, img, .image-wrapper, .image-wrapper, .image__item",
            )
            .forEach(item => {
                item.addEventListener("contextmenu", event => {
                    event.preventDefault()
                })
            })
    })

    return (
        <>
            <Header />
            <Content>{children}</Content>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node,
}

export default Layout
