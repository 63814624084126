import React from "react"
import "./footer.scss"

function getCurrentYear() {
    return new Date().getFullYear()
}

function Footer() {
    return (
        <footer className="footer">
            <small className="copyright">
                &copy; Copyright 2012-{getCurrentYear()}, Richard McCarthy. All
                rights reserved.
            </small>
        </footer>
    )
}

export default Footer
