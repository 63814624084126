import React from "react"

export function getAltText(graphqlNode) {
    try {
        return graphqlNode.custom.alt
    } catch (e) {
        return null
    }
}

export function addCloudinaryAttributionFlag(url) {
    return url
        .toLowerCase()
        .replace("q_auto,f_auto", "q_auto,f_auto,fl_keep_attribution")
}

export function GlobalHead() {
    const getGoatCounterUrl = goatCounterCode => {
        return `https://${goatCounterCode}.goatcounter.com/count`
    }

    const isProduction = process.env.GATSBY_ENV === "production"
    const goatCounterCode = isProduction
        ? process.env.GATSBY_GOATCOUNTER_PROD_CODE
        : process.env.GATSBY_GOATCOUNTER_DEV_CODE

    return (
        <>
            <meta
                name="description"
                content="Rich McCarthy Photography - a Photography portfolio by Rich McCarthy"
            />
            <meta property="og:site_name" content="Rich McCarthy Photography" />
            <meta property="og:title" content="Rich McCarthy Photography" />
            <meta
                property="og:url"
                content="https://www.richmccarthyphoto.com"
            />
            <meta
                property="og:image"
                content="https://res.cloudinary.com/dfjy6qd5f/image/upload/v1615419197/rm-photos/homepage/2.jpg"
            />
            <meta
                property="og:image:alt"
                content="Big waterfall along a gorge"
            />
            <meta
                property="og:description"
                content="A Photography portfolio by Rich McCarthy, based around all types of travel photography"
            />
            <meta property="og:type" content="website" />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="../../static/apple-touch-icon.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="../../static/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="../../static/favicon-16x16.png"
            />
            <script
                data-goatcounter={getGoatCounterUrl(goatCounterCode)}
                async
                src="//gc.zgo.at/count.js"
            />
            <title>Rich McCarthy Photography</title>
        </>
    )
}
