import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "./header.scss"

const navItems = [
    {
        title: "Albums",
        href: "/albums/",
        tabIndex: "2",
    },
    {
        title: "About",
        href: "/about/",
        tabIndex: "3",
    },
]

function NavItem({ text, url, currentPage, tabIndex }) {
    NavItem.propTypes = {
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        currentPage: PropTypes.string.isRequired,
        tabIndex: PropTypes.string.isRequired,
    }
    if (currentPage === url) {
        return (
            <li className="nav__item">
                <div className="current">{text}</div>
            </li>
        )
    } else {
        return (
            <li className="nav__item">
                <a className="link" href={url} tabIndex={tabIndex}>
                    {text}
                </a>
            </li>
        )
    }
}

function Nav({ currentPage }) {
    Nav.propTypes = {
        currentPage: PropTypes.string.isRequired,
    }
    return (
        <ul className="header__nav">
            {navItems.map(navItem => (
                <NavItem
                    key={navItem.title}
                    text={navItem.title}
                    url={navItem.href}
                    currentPage={currentPage}
                    tabIndex={navItem.tabIndex}
                />
            ))}
        </ul>
    )
}

function Logo({ text, currentPage }) {
    Logo.propTypes = {
        text: PropTypes.string.isRequired,
        currentPage: PropTypes.string.isRequired,
    }
    const tabIndex = "1"
    if (currentPage === "/") {
        return <h1 className="header__logo">{text}</h1>
    } else {
        return (
            <h1 className="header__logo">
                <a className="link" href="/" tabIndex={tabIndex}>
                    {text}
                </a>
            </h1>
        )
    }
}

function Header() {
    const [urlPath, setUrlPath] = useState("")

    useEffect(() => {
        setUrlPath(window.location.pathname)
    }, [])

    return (
        <div className="header">
            <Logo text="Rich McCarthy" currentPage={urlPath} />
            <Nav currentPage={urlPath} />
        </div>
    )
}

export default Header
